
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Country, State } from "country-state-city";
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { API } from './url';


const TrackATrader = () => {
  const [counterOn, setCounterOn] = useState(false)
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [citys, setCitys] = useState("")
  const [number, setNumber] = useState("")
  const [qurey, setQurey] = useState(false)
  const [profile, setProfile] = useState({})
  let fetchData = true

  const sum3UserAccessToken = localStorage.getItem('sum3UserAccessToken')

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(countryList);
  }, []);

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    setSelectedCountry(countryCode);
    const stateList = State.getStatesOfCountry(countryCode);
    setSelectedState("");
    setCities([]);
  };

  const getProfileDetails = async () => {
    try {
      const response = await fetch(`${API}/users/unique-id`, {
        method: 'post',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sum3UserAccessToken}`
        },
      });
      if (!response.ok) {
        throw new Error('Failed to Received Track A Trader Id');
      }
      const data = await response.json();
      setProfile(data?.payload)
      return data?.payload
    } catch (error) {
      Swal.fire({
        text: error,
        icon: "error"
      });
    }
  };

  const ragistartUserData = async () => {

    if (fetchData && sum3UserAccessToken) {
      fetchData = false
      await getProfileDetails()
    }
  }

  useEffect(() => {
    ragistartUserData()
  }, [sum3UserAccessToken])


  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [])

  useEffect(() => {
    window.onmessage = function (e) {
      if (e.data) {
        if (e.data.height) {
          document.getElementById("user-stats").style.height = e.data.height + "px";
        } else if (e.data.scrollTop) {
          window.scrollTo(0, 0);
        }
      }
    }

  }, [])

  const handelQueryDetails = async () => {
    try {
      const response = await fetch(`${API}/queries`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fullname: name, email: email, country: selectedCountry, city: citys, mobile: number }) // Fixed the 'body' casing and converted email to JSON format
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data?.message);
      }
      if (data?.isSuccess) {
        Swal.fire({
          text: data?.message,
          icon: "success"
        });
        setQurey(false)
      } else {
        Swal.fire({
          text: data?.message,
          icon: "error"
        });
      }
    } catch (error) {
      Swal.fire({
        text: error,
        icon: "error"
      });
    }
  };



  return (
    <div>
      {/* {
        qurey &&
        <div class='d-flex align-items-center justify-content-center position-fixed' style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.8)', top: '0', left: '0', zIndex: '100' }}>
          <div class='col-11 rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
            <br />
            <p class='fs-1 fwsb org'>Enter Your Details</p>
            <div class='row col-11 m-auto align-items-center justify-content-lg-around  justify-content-center'>
              <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                <input type="text" class="form-control" id="firstName" placeholder="First Name" value={name} onChange={(e) => setName(e.target.value)} />
                <label for="firstName">Full Name</label>
              </div>
              <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                <input type="text" class="form-control" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <label for="email">Email</label>
              </div>

              <div class="  col-lg-5 col-11 form-floating mt-4  p-0">
                <select
                  id="country"
                  name="country"
                  className="form-control"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <label for="country">Country</label>
              </div>
              <div class="col-lg-5 col-11 form-floating mt-4  p-0">
                <input type="text" class="form-control" id="city" placeholder="City" value={citys} onChange={(e) => setCitys(e.target.value)} />
                <label for="city">City</label>
              </div>

              <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                <input type="tel" class="form-control" id="number" maxLength={10} placeholder="Phone/Mobile Number" value={number} onChange={(e) => setNumber(e.target.value)} />
                <label for="number">Phone/Mobile Number</label>
              </div>
            </div>

            <div class='mt-4 d-flex align-items-center justify-content-center gap-4'>
              <button class='sumBtn mfs-4   rounded-5' onClick={handelQueryDetails} >Submit</button>
              <button class='sumBtn mfs-4   rounded-5' onClick={() => setQurey(false)} >Close</button>

            </div>
            <br />
          </div>
        </div>
      } */}
      <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
        <div class='d-flex align-items-center justify-content-center  col-11 m-auto rounded-4 bg-dark' style={{ height: '80%' }}>
          <div class='col-11 m-auto'>

            <p class='display-4 fwh org' data-aos="zoom-in">Track A Trader</p>
            {/* <button class='sumBtn mfs-4 rounded-5 mt-3' data-aos="zoom-in-up" onClick={() => setQurey(true)}>Start Your Investment Journey Today</button> */}
          </div>
        </div>
      </div>

      {profile?.data && <div class=' mb-5  rounded-4 col-11 m-auto p-3' style={{ background: '#f5f5f5' }} id="container-a201d262508ecfc7bde7b3498894df06">
        <iframe id="user-stats"
          src={`https://trackatrader.com/private/user-statistics?business_id=532&token=885107d983add63432e4fb4f2961e99f&userid=${profile?.data}&padding=no`}
          style={{ width: "100%", minHeight: '99vh' }} frameBorder="0" scrolling="no" allow="clipboard-read; clipboard-write; fullscreen">
        </iframe>
      </div>}

    </div>
  )
}

export default TrackATrader