import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { encryptData } from '../config';
import ScrollToTop from '../ScrollToTop';
import './Login/login.css';
import { API } from './url';

const LeavelUp = () => {
    const [questions, setQuestions] = useState();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [qusDetails, setQusDetails] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    let sum3UserId = localStorage.getItem("3sumUserId");
    let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");
    const navigate = useNavigate()


    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Step 4: Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    const getQuestion = async () => {
        try {
            const response = await fetch(`${API}/questions`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to Received Question');
            }
            const data = await response.json();
            const qnsWithPositions = data?.payload?.map((qnsItem) => {
                return {
                    ...qnsItem,
                    position: generateRandomPosition()
                };
            });
            setQuestions(qnsWithPositions)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    useEffect(() => {
        getQuestion()
    }, [])

    const generateRandomPosition = () => {
        // Generate random top between 15% and 65%
        const top = Math.floor(Math.random() * (40 - 15 + 1) + 15) + '%';
        // Generate random left between 10% and 65%
        const left = Math.floor(Math.random() * (50 - 10 + 1) + 10) + '%';
        const sTop = Math.floor(Math.random() * (90 - 10 + 1)) + 10 + '%';
        return { top, left, sTop };
    };

    const checkAns = async (questinId, answer) => {
        try {
            const response = await fetch(`${API}/questions/check`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ data: encryptData({ questionId: questinId, userAnswer: answer }) }) // Fixed the 'body' casing and converted email to JSON format
            });

            if (!response.ok) {
                throw new Error('Failed to submit your answer');
            }
            const data = await response.json();
            if (data?.payload?.isCorrect) {
                Swal.fire({
                    title: "Great! Right Answer",
                    text: `${data?.payload?.trivia}`,
                    icon: "success"
                });
            } else {
                Swal.fire({
                    title: "Oops! Wrong Answer",
                    html: `<b> Correct Answer is : </b> ${data?.payload?.correct_option} <br/>
                        <b> Trivia : </b> ${data?.payload?.trivia}  `,
                    icon: "error"
                });
            }
        } catch (error) {
            // console.error('Error:', error);
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    }

    const handleNext = () => {
        if (selectedOption) {
            const questionId = questions[currentQuestion].id;
            const existingIndex = qusDetails.findIndex(detail => detail.questionId === questionId);

            if (existingIndex >= 0) {
                const updatedDetails = [...qusDetails];
                updatedDetails[existingIndex].userAnswer = selectedOption;
                setQusDetails(updatedDetails);
            } else {
                setQusDetails(prevDetails => [
                    ...prevDetails,
                    { questionId, userAnswer: selectedOption }
                ]);
            }
            checkAns(questionId, selectedOption);
            setCurrentQuestion(currentQuestion + 1);
            const nextQuestionId = questions[currentQuestion + 1]?.id;
            const savedAnswer = qusDetails.find(detail => detail.questionId === nextQuestionId)?.userAnswer || null;
            setSelectedOption(savedAnswer);
        } else {
            Swal.fire({
                text: 'Please select an option before proceeding!',
                icon: 'warning',
            });
        }
    };

    const handlePrev = () => {
        if (currentQuestion > 0) {
            const previousQuestionId = questions[currentQuestion - 1]?.id;
            const savedAnswer = qusDetails.find(detail => detail.questionId === previousQuestionId)?.userAnswer || null;

            setCurrentQuestion(currentQuestion - 1);
            setSelectedOption(savedAnswer);
        }
    };

    const handleSubmit = () => {
        // Check if an option is selected before moving to the next question
        if (selectedOption !== null) {
            const lastQuestionId = questions[currentQuestion].id;

            const existingIndex = qusDetails.findIndex(detail => detail.questionId === lastQuestionId);

            if (existingIndex >= 0) {
                // If it exists, update the answer
                setQusDetails(prevDetails => {
                    const updatedDetails = [...prevDetails];
                    updatedDetails[existingIndex].userAnswer = selectedOption;
                    submitDetails(updatedDetails);
                    return updatedDetails;
                });
            } else {
                // If it doesn't exist, add a new entry for the last question
                setQusDetails(prevDetails => {
                    const updatedDetails = [
                        ...prevDetails,
                        {
                            questionId: lastQuestionId,
                            userAnswer: selectedOption
                        }
                    ];

                    // Ensure submitDetails is called after the state update
                    submitDetails(updatedDetails);
                    return updatedDetails;
                });
            }
        } else {
            Swal.fire({
                text: 'Please select an option to continue!',
                icon: 'warning',
            });
        }
    };



    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const submitDetails = async (e) => {
        try {
            const response = await fetch(`${API}/users/upgrade-role`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },
                body: JSON.stringify({ data: encryptData({ answers: e }) }) // Fixed the 'body' casing and converted email to JSON format
            });

            if (!response.ok) {
                throw new Error('Failed to send your Quize');
            }
            const data = await response.json();

            if (data?.isSuccess) {
                Swal.fire({
                    text: `${data?.message}`,
                    icon: "success"
                });
                if (data?.payload?.accessToken) {
                    localStorage.setItem("sum3UserAccessToken", data?.payload?.accessToken);
                }
                localStorage.setItem("sum3UserRole", data?.payload?.role);
                navigate(`/${data?.payload?.role}-invest`)
            } else {
                Swal.fire({
                    text: data?.message,
                    icon: "error"
                });
            }
        } catch (error) {
            // console.error('Error:', error);
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    }

    useEffect(() => {
        const qnsBackground = document.querySelector('.qnsSlide');
        if (qnsBackground) {
            const totalQuestions = questions?.length;
            const bgPositionX = (currentQuestion / (totalQuestions - 1)) * 100;
            qnsBackground.style.backgroundPositionX = `${bgPositionX}%`;
        }
    }, [currentQuestion, questions]);





    return (
        <div className="qnsSection">
            <ScrollToTop />
            {questions?.length > 0 && (
                <div className="qnsSlide">
                    <div class='qnslN fs-3 fw-semibold '  >{currentQuestion + 1}</div>
                    {currentQuestion < questions.length - 1 ?
                        <div class='qnsrN fs-3 fw-semibold ' onClick={handleNext}>{currentQuestion + 2}</div>
                        :
                        <button className="sumBtn mfs-2
                         rounded-5 position-absolute bg-white qnsrB" onClick={() => handleSubmit()} > Submit  </button>
                    }

                    <div className={`col-lg-5 col-md-6 col-11 rounded-4 border ansDivWind bg-white `}
                        style={windowWidth >= 770 ? { top: questions?.[currentQuestion]?.position?.top, left: questions?.[currentQuestion]?.position?.left, zIndex: '5' } : { bottom: '10%', left: '3%', zIndex: '5' }}>
                        <div className="col-11 m-auto my-4 text-start position-relative">
                            <div class=' position-absolute fs-3 fw-semibold p-3' style={{ top: "-20px", left: '-20px', opacity: '0.1', zIndex: '-1', color: 'red' }}><p class='display-1 fwh'>{currentQuestion + 1}</p></div>
                            <p class='fwsb'>{questions?.[currentQuestion]?.title}</p>
                            <div class='mt-2' style={{ zIndex: '8' }}>
                                {questions?.[currentQuestion]?.options?.map((e, oIndex) => (
                                    <div className="form-check" key={oIndex} >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`RadioIndex${currentQuestion}`}
                                            id={`RadioIndexWind${oIndex}`}
                                            value={e}
                                            onChange={() => handleOptionChange(e)} // Set selected option
                                            checked={selectedOption === e}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <label
                                            className="form-check-label fwr"
                                            htmlFor={`RadioIndexWind${oIndex}`}
                                            style={{ cursor: 'pointer' }}

                                        >
                                            {e}
                                        </label>
                                    </div>
                                ))}
                            </div>

                            <div className="d-flex gap-2 mt-4">
                                {currentQuestion < questions.length - 1 ? (
                                    <>

                                        <button
                                            className="sumBtn mfs-2 rounded-5"
                                            onClick={handleNext}

                                        >
                                            Next
                                        </button>
                                    </>
                                ) : (
                                    <button className="sumBtn mfs-2 rounded-5" onClick={() => handleSubmit()}>
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default LeavelUp


