import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './invest.css'
import { FaArrowRightLong } from 'react-icons/fa6';
import { API } from '../url';
import Swal from 'sweetalert2';

const moveText = ["How to Invest", "FX Pro", "VPS Server", "How to Invest", "FX Pro", "VPS Server", "How to Invest", "FX Pro", "VPS Server", "How to Invest", "FX Pro", "VPS Server", "How to Invest", "FX Pro", "VPS Server",]

const ourProduct = [
    {
        title: "Fx-Pro",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "CMC Markets",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "How to Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Wallstreet Coach",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Wallstreet 101",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Forex UPS",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "FINLATO",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Track a Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Mark Dickinson",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
]


const Invest1 = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [products, setProducts] = useState()
    const [details, setDetails] = useState()
    let sum3UserRole = localStorage.getItem("sum3UserRole");
    let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");

    useEffect(() => {
        const isMessageShown = sessionStorage.getItem("isMessageShown");
        // if (!isMessageShown) {
        //     Swal.fire({
        //         title: "Thank you for providing all the information we need!",
        //         icon: "info",
        //         html: `
        //             A personal advisor will be in touch with you soon to guide you through your personalized service plan. We look forward to assisting you on your journey. </br></br>
        //             If you have any questions in the meantime, feel free to reach out to us at <a target="_blank" href="https://www.3sum.ai/" autofocus>three@3sum.ai.</a></br>
        //             </br>Happy Investing! `,
        //         showCloseButton: false,
        //         showCancelButton: false,
        //         focusConfirm: false,
        //     });

        //     sessionStorage.setItem("isMessageShown", "true");
        // }
    }, []);

    const getProductDetails = async (sum3UserAccessToken) => {
        try {
            const response = await fetch(`${API}/users/products`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            setProducts(data?.payload)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };
    const getPageDetails = async (sum3UserAccessToken) => {
        try {
            const response = await fetch(`${API}/content/product-service?page=Basic`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            setDetails(data?.payload)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };
    useEffect(() => {
        if (sum3UserAccessToken) {
            getProductDetails(sum3UserAccessToken)
            getPageDetails(sum3UserAccessToken)
        } else {
            // navigate('/log-in')
        }
    }, [])

    console.log(sum3UserAccessToken);





    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    useEffect(() => {
        if (sum3UserRole !== "Basic") {
            navigate('/log-in')
        }
    }, sum3UserRole)

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigate = useNavigate()

    const handelCheckService = async (id) => {
        try {
            const response = await fetch(`${API}/products/product-usage/${id}`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                      Authorization: `Bearer ${sum3UserAccessToken}`
                },   // Fixed the 'body' casing and converted email to JSON format
            }); 
            const data = await response.json(); 
        } catch (error) {  Swal.fire({
                text: error,
                icon: "error"
            });
        }
    }

    return (
        <div style={{ background: '#000000', overflow: 'hidden' }}>
            <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
                <div class='col-lg-7 col-11 m-auto text-white'>
                    <div>
                        <p class='mfs-8 fwsb' data-aos="zoom-in">{details?.text1} <span style={{ color: '#FF823C' }}>{details?.text2}</span> <br /> {details?.text3} <span class='px-4 rounded-4 investEarn'  > {details?.text2}</span></p>
                        <p class='fwt fs-5 col-11 m-auto tJustify mt-3' data-aos="fade-up">{details?.description}</p>
                        {/* <button class='sumBtn mfs-3 rounded-5 my-3' onClick={() => navigate('/log-in')}>Log in</button> */}
                        <div class='arrows'>
                            <p class='d1 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                            <p class='d2 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                            <p class='d3 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                        </div>
                    </div>
                </div>
                <div class='arrows '>
                    <p class='d1 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                    <p class='d2 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                    <p class='d3 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                </div>
            </div>
            <div style={{ background: '#FF823C', overflow: 'hidden', transform: 'rotate(10deg)', width: '120%', marginLeft: '-5%' }}>
                <div class='d-flex gap-4 p-2' style={{ transform: `translateX(-${scrollPosition}px)` }}>
                    {moveText?.map((el) => {
                        return (
                            <div class='d-flex align-items-center gap-5 flex-nowrap text-black'>
                                <p class='text-nowrap fs-2 fwh'>{el}</p>
                                <div class={'hamburger2 is-active'} id="hamburger2" >
                                    <span class={`lines`} style={{ background: '#000', height: '10px' }}></span>
                                    <span class={`lines`} style={{ background: '#000', height: '15px' }}></span>
                                    <span class={`lines`} style={{ background: '#000', height: '20px' }}></span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div class='col-11 m-auto text-white my-5' >
                <p class='mfs-7 fwb' data-aos="fade-up">Our Recommendation</p>

                <div class='d-flex gap-3 flex-wrap  mt-5 '>
                    {products?.map((el, index) => {
                        return (
                            <Link
                                activeClass="active"
                                to={`investD${index}`}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                <button data-aos="fade-up" class='btn btn-outline-light text-wrap rounded-5 fs-4 px-sm-4'>{el?.title}</button>
                            </Link>
                        )
                    })}
                </div>
            </div>

            <div class='col-11 m-auto'>
                {
                    products?.map((el, index) => {
                        return (
                            <div data-aos="fade-up" class='row gap-lg-0 gap-4 border border-light rounded-5 p-0 m-0 mt-5' id={`investD${index}`} style={{ overflow: 'hidden', position: 'relative' }}  onClick={()=>handelCheckService(el?.id)}  >

                                <div class='col-lg-7' style={{ zIndex: '1' }}>
                                    <div class='text-start col-11 m-auto my-3 text-white'>
                                        <p class='fs-1 fweb'>{el?.title}</p>
                                        <p class='fs-5 fwt mt-2'>{el?.subtitle}</p>
                                        <div dangerouslySetInnerHTML={{ __html: el?.description }}></div>

                                        <button class='sumBtn mfs-3 rounded-5 mt-3' onClick={() => window.open(el?.link, '_blank')}>{el?.buttonText}</button>

                                    </div>
                                </div>

                                <div class='col-lg-5 rounded-5 p-0 d-flex align-items-center   ' style={{ overflow: 'hidden', position: 'relative', zIndex: '1', background: '#fff3ec' }}>
                                    <div class='proImgeIcon' onClick={() => window.open(el?.link, '_blank')}> <p class='fs-3 fw-bold text-white'><FaArrowRightLong /></p> </div>

                                    <div class='col-11 m-auto' style={{ minHeight: '100px' }}>
                                        <img class='rounded-4' src={`${API}` + `${el?.image}`} style={{ width: '100%' }} alt="" onClick={() => window.open(el?.link, '_blank')} />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <br /><br />

        </div>
    )
}

export default Invest1