import AOS from 'aos';
import 'aos/dist/aos.css';
import { Country, State } from "country-state-city";
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { API } from './url';



const HowToTrade = () => {
  const [qurey, setQurey] = useState(false)
  const [email, setEmail] = useState("")
  const [selectedCountry, setSelectedCountry] = useState("");
  const [citys, setCitys] = useState("")
  const [number, setNumber] = useState("")
  const [name, setName] = useState("")
  const [countries, setCountries] = useState([]);


  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [])

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    setSelectedCountry(countryCode);
    const stateList = State.getStatesOfCountry(countryCode);
  };

  useEffect(() => {
    const countryList = Country.getAllCountries();
    setCountries(countryList);
  }, []);

  const handelQueryDetails = async () => {
    try {
      const response = await fetch(`${API}/queries`, {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fullname: name, email: email, country: selectedCountry, city: citys, mobile: number }) // Fixed the 'body' casing and converted email to JSON format
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data?.message);
      }
      if (data?.isSuccess) {
        Swal.fire({
          text: data?.message,
          icon: "success"
        });
        setQurey(false)
      } else {
        Swal.fire({
          text: data?.message,
          icon: "error"
        });
      }
    } catch (error) {
      // console.error('Error:', error);
      Swal.fire({
        text: error,
        icon: "error"
      });
    }
  };

  return (
    <div>
      {
        qurey &&
        <div class='d-flex align-items-center justify-content-center position-fixed' style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.8)', top: '0', left: '0', zIndex: 99 }}>
          <div class='col-11 rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
            <br />
            <p class='fs-1 fwsb org'>Enter Your Details</p>
            <div class='row col-11 m-auto align-items-center justify-content-lg-around  justify-content-center'>
              <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                <input type="text" class="form-control" id="firstName" placeholder="First Name" value={name} onChange={(e) => setName(e.target.value)} />
                <label for="firstName">Full Name</label>
              </div>
              <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                <input type="text" class="form-control" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <label for="email">Email</label>
              </div>

              <div class="  col-lg-5 col-11 form-floating mt-4  p-0">
                <select
                  id="country"
                  name="country"
                  className="form-control"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.isoCode} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <label for="country">Country</label>
              </div>
              <div class="col-lg-5 col-11 form-floating mt-4  p-0">
                <input type="text" class="form-control" id="city" placeholder="City" value={citys} onChange={(e) => setCitys(e.target.value)} />
                <label for="city">City</label>
              </div>

              <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                <input type="tel" class="form-control" id="number" maxLength={10} placeholder="Phone/Mobile Number" value={number} onChange={(e) => setNumber(e.target.value)} />
                <label for="number">Phone/Mobile Number</label>
              </div>
            </div>

            <div class='mt-4 d-flex align-items-center justify-content-center gap-4'>
              <button class='sumBtn mfs-4   rounded-5' onClick={handelQueryDetails} >Submit</button>
              <button class='sumBtn mfs-4   rounded-5' onClick={() => setQurey(false)} >Close</button>

            </div>
            <br />
          </div>
        </div>
      }
      <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>

        <div class='d-flex align-items-center justify-content-center  col-11 m-auto rounded-4 bg-dark ' style={{ height: '80%' }}>
          <div class='col-11 m-auto'>

            <p class='mfs-7 fwh org' data-aos="zoom-in">How To Trade</p>
            <p class='mfs-6 text-white fwl' data-aos="zoom-in-up">Empowering Traders, Transforming Futures</p>
            <button class='sumBtn mfs-4 rounded-5 mt-3' data-aos="zoom-in-up" onClick={() => setQurey(true)}>Unlock Your Trading Potential</button>
          </div>
        </div>
      </div>
      <div class='col-11 m-auto text-start'>

        <div>
          <p class='fs-3 fwb mt-5' data-aos="fade-up">About HowToTrade</p>
          <p class='mt-2 tJustify' data-aos="fade-up">HowToTrade’s mission is to find talented traders and to give them the environment needed to achieve financial independence.</p>
        </div>

        <div>
          <p class='fs-3 fwb mt-5' data-aos="fade-up">Who Are We?</p>
          <p class='mt-2 tJustify' data-aos="fade-up">The team at HowToTrade is hyper-focused on delivering on our mission to help traders reach whatever goals they have, most of the time it’s financial independence and the ability to trade full-time.</p>
          <p class='mt-2 tJustify' data-aos="fade-up">We’re fully aware that no two traders are created equally. That’s why when 100 people are taught the same trading strategy, the results will vary wildly. Trading is way more subjective, it’s driven by emotional intelligence. Those who have the skills and emotional intelligence are the traders we’d like to find and turn into career traders.</p>
          <p class='mt-2 tJustify' data-aos="fade-up">Our team of 65 remote team members works around the clock to ensure our customers are informed, confident and supported. We come from all walks of life and most of us are experienced traders, so we know the pain points traders have.</p>
          <p class='mt-2 tJustify' data-aos="fade-up">Part of the <a class='org fwl' href='https://thinkhuge.net/' target="_blank">Think Huge group,</a> our reputation has allowed us to become one of the biggest and most well-regarded businesses in the trading industry. Everything we’ve developed has been to help traders like you with your day-to-day trading problems.</p>
        </div>


        <div class='d-flex align-items-center justify-content-center m-auto mt-4'>
          <button class='sumBtn mfs-4 rounded-5 mt-3' data-aos="zoom-in-up" onClick={() => setQurey(true)}>Start Your Trading Journey Today</button>
        </div>
      </div>
      <br />
      <br />
    </div>
  )
}

export default HowToTrade