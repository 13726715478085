import AOS from 'aos';
import 'aos/dist/aos.css';
import { Country, State } from "country-state-city";
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import Swal from 'sweetalert2';
import { API } from './url';

const QuantumGlobal = () => {
    const [counterOn, setCounterOn] = useState(false)
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [citys, setCitys] = useState("")
    const [number, setNumber] = useState("")
    const [qurey, setQurey] = useState(false)
 

    useEffect(() => {
        const countryList = Country.getAllCountries();
        setCountries(countryList);
    }, []);

    const handleCountryChange = (e) => {
        const countryCode = e.target.value;
        setSelectedCountry(countryCode);
        const stateList = State.getStatesOfCountry(countryCode);
        setSelectedState("");
        setCities([]);
    };


    const navigate = useNavigate()
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])


    useEffect(() => {
        // Create a script element
        const script = document.createElement("script");
        script.src = "https://quantendo.com/widgets/l/a201d262508ecfc7bde7b3498894df06";
        script.async = true;

        // Append the script to the body
        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // Create a script element
        const script = document.createElement("script");
        script.src = "https://quantendo.com/widgets/l/a201d262508ecfc7bde7b3498894df06";
        script.async = true;

        // Append the script to the body
        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // Create a script element
        const script = document.createElement("script");
        script.src = "https://quantendo.com/widgets/l/ea188d002d19df8da73e312601f8cec5";
        script.async = true;

        // Append the script to the body
        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // Create a script element
        const script = document.createElement("script");
        script.src = "https://quantendo.com/widgets/l/ad470461ab9622fdd1682b88fd12a8ab";
        script.async = true;

        // Append the script to the body
        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handelQueryDetails = async () => {
        try {
            const response = await fetch(`${API}/queries`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fullname: name, email: email, country: selectedCountry, city: citys, mobile: number}) // Fixed the 'body' casing and converted email to JSON format
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            if (data?.isSuccess) { 
                Swal.fire({
                    text: data?.message,
                    icon: "success"
                }); 
                setQurey(false)
            } else {
                Swal.fire({
                    text: data?.message,
                    icon: "error"
                });
            }
        } catch (error) {
            // console.error('Error:', error);
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };
    return (
        <div>
            {
                qurey &&

                <div class='d-flex align-items-center justify-content-center position-fixed z-1' style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.8)', top: '0', left: '0' }}>
                    <div class='col-11 rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <br />
                        <p class='fs-1 fwsb org'>Enter Your Details</p>
                        <div class='row col-11 m-auto align-items-center justify-content-lg-around  justify-content-center'>
                            <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="text" class="form-control" id="firstName" placeholder="First Name" value={name} onChange={(e) => setName(e.target.value)} />
                                <label for="firstName">Full Name</label>
                            </div>
                            <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="text" class="form-control" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <label for="email">Email</label>
                            </div>

                            <div class="  col-lg-5 col-11 form-floating mt-4  p-0">
                                <select
                                    id="country"
                                    name="country"
                                    className="form-control"
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                >
                                    <option value="">Select Country</option>
                                    {countries.map((country) => (
                                        <option key={country.isoCode} value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="country">Country</label>
                            </div>
                            <div class="col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="text" class="form-control" id="city" placeholder="City" value={citys} onChange={(e) => setCitys(e.target.value)} />
                                <label for="city">City</label>
                            </div>

                            <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="tel" class="form-control" id="number" maxLength={10} placeholder="Phone/Mobile Number" value={number} onChange={(e) => setNumber(e.target.value)} />
                                <label for="number">Phone/Mobile Number</label>
                            </div>
                        </div>

                        <div class='mt-4 d-flex align-items-center justify-content-center gap-4'>
                            <button class='sumBtn mfs-4   rounded-5' onClick={handelQueryDetails} >Submit</button>
                            <button class='sumBtn mfs-4   rounded-5' onClick={() => setQurey(false)} >Close</button>

                        </div>
                        <br />
                    </div>
                </div>
            }
            <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
                <div class='d-flex align-items-center justify-content-center  col-11 m-auto rounded-4 bg-dark' style={{ height: '80%' }}>
                    <div class='col-11 m-auto'>

                        <p class='display-4 fwh org' data-aos="zoom-in">Elevate Your Investments with Quantum-Driven Managed Portfolios</p>
                        <p class='fs-3 col-lg-6 col-md-10 m-auto text-white fwl' data-aos="zoom-in-up">Experience superior returns through cutting-edge algorithmic trading solutions.</p>
                        <button class='sumBtn mfs-4 rounded-5 mt-3' data-aos="zoom-in-up" onClick={() => setQurey(true)}>Start Your Quantum Investment Journey Today</button>
                    </div>
                </div>
            </div>

            <div class='col-11 m-auto'>
                <p class='fs-5 col-sm-11 m-auto' data-aos="fade-up">Welcome to a new era of investment management. In collaboration with Quantum Global,
                    we bring you <b>fully managed portfolios</b> powered by advanced algorithmic strategies and
                    market intelligence. This partnership combines financial expertise with Quantum Global’s
                    cutting-edge technology to deliver consistent, superior returns tailored to your goals.</p>
            </div>

            <div class='mt-5  rounded-4 col-11 m-auto p-3' style={{ background: '#f5f5f5' }} id="container-a201d262508ecfc7bde7b3498894df06"></div>

            {/* <div  class='mt-5  rounded-4 col-11 m-auto p-3 'style={{ background:'#ececec'}} id="container-ea188d002d19df8da73e312601f8cec5"></div> 
         
            <div class='mt-5  rounded-4 col-11 m-auto p-3' style={{ background:'#f5f5f5'}} id="container-ad470461ab9622fdd1682b88fd12a8ab"></div> */}





            <div class='mt-4'>
                <p class='col-lg-9 col-md-10 col-11 m-auto org fs-1 fw-bold' data-aos="fade-up" >
                    Achieve Investment Excellence with the Unique Combination of AI and Human Expertise.
                </p>
                <div class='row col-11 m-auto gap-lg-0 gap-5 mt-5' id='home7'>

                    <div class='col-lg-4' data-aos="fade-up"  >
                        <div class='col-11  rounded-top-5 h-100 text-start p-2 m-auto' style={{ background: '#f2f2f2' }}>
                            <div class=' d-flex align-items-center  mfs-8 '>
                                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                    <p class='mfs-8 fwsb'  ><span  >{counterOn && <CountUp start={0} end={15} delay={0} />}</span></p>
                                </ScrollTrigger>
                                <p class='fwsb'>
                                    +
                                </p>
                            </div>
                            <div class='col-11 m-auto text-end'>
                                <p class='mfs-6 fwm my-3 text-end'>Years of Research</p>
                            </div>

                        </div>
                    </div>

                    <div class='col-lg-4' data-aos="fade-up" >
                        <div class='col-11  rounded-top-5 h-100 text-start p-2 m-auto' style={{ background: '#f2f2f2' }}>
                            <div class=' d-flex align-items-center  mfs-8 '>
                                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                    <p class='mfs-8 fwsb'  ><span  >{counterOn && <CountUp start={0} end={2} delay={0} />}</span></p>
                                </ScrollTrigger>
                                <p class='fwsb'>
                                    x
                                </p>
                            </div>
                            <div class='col-11 m-auto text-end'>
                                <p class='mfs-6 fwm my-3 text-end'>Awarded as Top 3 Finalist in World Trading Championships</p>
                            </div>

                        </div>
                    </div>

                    <div class='col-lg-4' data-aos="fade-up" >
                        <div class='col-11  rounded-top-5 h-100 text-start p-2 m-auto' style={{ background: '#f2f2f2' }}>
                            <div class=' d-flex align-items-center  mfs-8 '>
                                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                    <p class='mfs-8 fwsb'  >$<span  >{counterOn && <CountUp start={0} end={20} delay={0} />}</span></p>
                                </ScrollTrigger>
                                <p class='fwsb'>
                                    M+
                                </p>
                            </div>
                            <div class='col-11 m-auto text-end'>
                                <p class='mfs-6 fwm my-3 text-end'>AUM managed by our algos</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class=' mt-5' style={{ background: '#402E32' }}>
                <br />
                <div class='col-11 m-auto text-white '>
                    <p class=' display-5 fw-bold text-white ' data-aos="zoom-in">Benefits</p>

                    <div class='row  justify-content-center' >
                        <div class='col-lg-4 col-md-6 col-11 my-4' data-aos="fade-up">
                            <div class='col-11 m-auto rounded-3 h-100' style={{ background: 'rgba(255,255,255,0.1)' }}>
                                <br />
                                <div class='col-11 m-auto text-start '>
                                    <p class='fs-2 fw-semibold'>Consistent Returns</p>
                                    <p class='tJustify'>Achieve superior performance, consistently outperforming market benchmarks with absolute return strategies.</p>
                                </div>
                                <br />
                            </div>
                        </div>

                        <div class='col-lg-4 col-md-6 col-11 my-4' data-aos="fade-up">
                            <div class='col-11 m-auto rounded-3 h-100' style={{ background: 'rgba(255,255,255,0.1)' }}>
                                <br />
                                <div class='col-11 m-auto text-start  '>
                                    <p class='fs-2 fw-semibold'>Liquidity at Your Fingertips</p>
                                    <p class='tJustify'>Retain full access to your funds, with no withdrawal fees or hidden charges.</p>
                                </div>
                                <br />
                            </div>
                        </div>

                        {/* <div class='col-lg-4 col-md-6 col-11 my-4' data-aos="fade-up">
                            <div class='col-11 m-auto rounded-3 h-100' style={{ background: 'rgba(255,255,255,0.1)' }}>
                                <br />
                                <div class='col-11 m-auto text-start '>
                                    <p class='fs-2 fw-semibold'>Risk Management</p>
                                    <p class='tJustify'>Your investments are safeguarded by predefined risk parameters, ensuring a
                                        balance between growth and protection.</p>
                                </div>
                                <br />
                            </div>
                        </div> */}

                        <div class='col-lg-4 col-md-6 col-11 my-4 ' data-aos="fade-up">
                            <div class='col-11 m-auto rounded-3 h-100' style={{ background: 'rgba(255,255,255,0.1)' }}>
                                <br />
                                <div class='col-11 m-auto text-start '>
                                    <p class='fs-2 fw-semibold'>Profit in All Market Conditions</p>
                                    <p class='tJustify'>Leverage innovative strategies to capitalize on both rising and falling markets.</p>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>


            <div class='' style={{}}>
                <br />
                <div class='col-11 m-auto '>
                    <p class=' display-5 fw-bold text-white org' data-aos="zoom-in">Portfolio Features</p>

                    <div class='row  justify-content-center text-white' >
                        <div class='col-lg-4 col-md-6 col-11 my-4' data-aos="fade-up">
                            <div class='col-11 m-auto rounded-3 h-100 ' style={{ background: 'rgba(255,130,60)' }}>
                                <br />
                                <div class='col-11 m-auto text-start '>
                                    <p class='fs-2 fw-semibold'>Advanced Algorithmic Strategies</p>
                                    <p class='tJustify'>Harness the power of Quantum Global's sophisticated algorithms to automate trading decisions, ensuring precision and eIiciency in every market condition.</p>
                                </div>
                                <br />
                            </div>
                        </div>

                        <div class='col-lg-4 col-md-6 col-11 my-4' data-aos="fade-up">
                            <div class='col-11 m-auto rounded-3 h-100 ' style={{ background: 'rgba(255,130,60)' }}>
                                <br />
                                <div class='col-11 m-auto text-start '>
                                    <p class='fs-2 fw-semibold'>Diversified Asset Classes</p>
                                    <p class='tJustify'>Enjoy access to a wide range of assets, including commodities, bonds, currencies, and stock indices, through expertly managed futures contracts.</p>
                                </div>
                                <br />
                            </div>
                        </div>

                        <div class='col-lg-4 col-md-6 col-11 my-4' data-aos="fade-up">
                            <div class='col-11 m-auto rounded-3 h-100 ' style={{ background: 'rgba(255,130,60)' }}>
                                <br />
                                <div class='col-11 m-auto text-start '>
                                    <p class='fs-2 fw-semibold'>Transparency and Control</p>
                                    <p class='tJustify'>Maintain full control over your funds with real-time insights into portfolio performance, empowering you to make informed decisions.</p>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>




            <div class='col-11 m-auto bg-blackGray rounded-4 my-4'>
                <br />
                <div class='col-11 m-auto text-start'>
                    <p class='fs-1 fw-bold org' data-aos="fade-up">How It Works</p>
                    <div class='text-white'>

                        <div class='mt-2' data-aos="fade-up">
                            <b class='fs-5'>1. Choose a Program</b>
                            <p>Select a managed portfolio program tailored to your investment objectives.</p>
                        </div>
                        <div class='mt-2' data-aos="fade-up">
                            <b class='fs-5'>2. Open and Fund Your Account</b>
                            <p>Set up your account with a reputable brokerage firm, such as Phillip Capital, for
                                seamless integration with our platform.</p>
                        </div>
                        <div class='mt-2' data-aos="fade-up">
                            <b class='fs-5'>3. Connect and Trade</b>
                            <p>Sign a Letter of Direction to authorize the portfolio management team to trade on
                                your behalf.</p>
                        </div>
                        <div class='mt-2' data-aos="fade-up">
                            <b class='fs-5'>4. Monitor and Adjust</b>
                            <p>Access your account 24/7 to review performance metrics, ensuring alignment with
                                your financial goals.</p>
                        </div>
                        <br />
                    </div>

                </div>

            </div>

            <div class='col-11 rounded-5  m-auto' style={{ background: 'rgba(255,130,60,0.1)', border: '2px solid #ff843f ' }} data-aos="zoom-in-up">
                <br />
                <div class='col-11 m-auto'>
                    <p class='fs-1 fw-bold org'>Take the Next Step in Your Investment Journey</p>
                    <p class='text-black fs-5 col-lg-7 m-auto'>Schedule a free consultation with our experts to explore how Quantum Global’s managed
                        portfolios can help you achieve your financial goals.</p>
                    {/* <button class=' rounded-5 mt-3 btn btn-outline-light' onClick={() => navigate('/log-in')}>Start Now </button> */}
                    <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Start Now</button>
                </div>
                <br />
            </div>
            <br />
        </div>
    )
}

export default QuantumGlobal