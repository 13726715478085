import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useState } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { FaArrowDownLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import Swal from 'sweetalert2';
import { API } from '../url';
import './invest.css';


const ourProduct = [
    {
        title: "Fx-Pro",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "CMC Markets",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "How to Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Wallstreet Coach",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Wallstreet 101",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Forex UPS",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "FINLATO",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Track a Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Mark Dickinson",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
]


const Invest2 = () => {
    const [scrollPos, setScrollPos] = useState(0);
    const [products, setProducts] = useState()
    const [details, setDetails] = useState()


    let sum3UserRole = localStorage.getItem("sum3UserRole");
    let sum3UserAccessToken = localStorage.getItem("sum3UserAccessToken");


    useEffect(() => {
        const isMessageShown = sessionStorage.getItem("isMessageShown");
        // if (!isMessageShown) {
        //     Swal.fire({
        //         title: "Thank you for providing all the information we need!",
        //         icon: "info",
        //         html: `
        //         A personal advisor will be in touch with you soon to guide you through your personalized service plan. We look forward to assisting you on your journey. </br></br>
        //         If you have any questions in the meantime, feel free to reach out to us at <a target="_blank" href="https://www.3sum.ai/" autofocus>three@3sum.ai.</a></br>
        //         </br>Happy Investing! `,
        //         showCloseButton: false,
        //         showCancelButton: false,
        //         focusConfirm: false,
        //     });

        //     sessionStorage.setItem("isMessageShown", "true");
        // }
    }, []);

    const getProductDetails = async (sum3UserAccessToken) => {
        try {
            const response = await fetch(`${API}/users/products`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            setProducts(data?.payload)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };

    const getPageDetails = async (sum3UserAccessToken) => {
        try {
            const response = await fetch(`${API}/content/product-service?page=Intermediate`, {
                method: 'get',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data?.message);
            }
            setDetails(data?.payload)
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    };
    useEffect(() => {
        if (sum3UserAccessToken) {
            getProductDetails(sum3UserAccessToken)
            getPageDetails(sum3UserAccessToken)
        } else {
            // navigate('/log-in')
        }
    }, [sum3UserAccessToken])



    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])


    useEffect(() => {
        if (sum3UserRole !== "Intermediate") {
            navigate('/log-in')
        }
    }, sum3UserRole)

    useEffect(() => {
        const handleScroll = () => {
            setScrollPos(window.pageYOffset);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);



    const navigate = useNavigate()

    const handelCheckService = async (id) => {
        try {
            const response = await fetch(`${API}/products/product-usage/${id}`, {
                method: 'POST',
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sum3UserAccessToken}`
                },   // Fixed the 'body' casing and converted email to JSON format
            });
            const data = await response.json();
        } catch (error) {
            Swal.fire({
                text: error,
                icon: "error"
            });
        }
    }
    return (
        <div style={{ overflow: 'hidden' }}>
            <div class='col-11 m-auto row align-items-center justify-content-center text-black mt-lg-0 mt-5 gap-lg-0 gap-4' style={{ height: '100vh' }}>
                <div class='col-lg-7 text-start mt-lg-0 mt-5'>
                    <p class='mfs-8 fwsb' data-aos="zoom-in">{details?.text1} <span style={{ color: '#FF823C' }}>{details?.text2}</span></p>
                    <div class='d-flex align-items-center gap-4 flex-warp '>
                        <div class='d-flex align-items-center'>
                            <div class='textImage' data-aos="zoom-in"><img src={require('../../Images/man1.png')} alt="" /></div>
                            <div class='textImage' data-aos="zoom-in" style={{ marginLeft: '-20px' }}><img src={require('../../Images/man2.png')} alt="" /></div>
                            <div class='textImage' data-aos="zoom-in" style={{ marginLeft: '-20px' }}><img src={require('../../Images/man3.png')} alt="" /></div>
                            <div class='textImage' data-aos="zoom-in" style={{ marginLeft: '-20px' }}><img src={require('../../Images/man4.png')} alt="" /></div>
                        </div>
                        <p class='mfs-8 fwsb' data-aos="zoom-in">{details?.text3}</p>
                        <div class='textImagelw' data-aos="zoom-in"><img src={require('../../Images/man.png')} alt="" /></div>

                    </div>
                    <div class='textImagels my-2' data-aos="zoom-in"><img src={require('../../Images/man.png')} alt="" /></div>
                    <p class='mfs-8 fwsb' data-aos="zoom-in"> <span style={{ color: '#FF823C' }}>{details?.text4}</span></p>
                    {/* <button class='sumBtn mfs-3 rounded-5 my-2' onClick={() => navigate('/log-in')}>Log in</button> */}
                </div>
                <div class='col-lg-5'>
                    <div class='col-11 m-auto'>
                        <p class='tJustify fwt fs-5' data-aos="fade-up">{details?.description}</p>
                        <br />
                        <div class='d-flex justify-content-lg-start justify-content-center'>
                            <div class='investNow ' data-aos="zoom-in">
                                <img src={require('../../Images/investNow.png')} style={{ transform: `rotate(${scrollPos % 360}deg)` }} alt="" />
                                <div class='investNowArrow fs-1 fw-bold'>

                                <FaArrowDownLong class='intermediateArrow' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class='arrows'>
                <p class='d1 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                <p class='d2 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                <p class='d3 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
            </div> */}
            <br />
            <div class='col-11 m-auto my-5 text-black' >
                <p class='mfs-7 fwb' data-aos="fade-up">Our Recommendation</p>

                <div class='d-flex gap-3 flex-wrap  my-5 '>
                    {products?.map((el, index) => {
                        return (
                            <Link
                                activeClass="active"
                                to={`investD${index}`}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                <button data-aos="fade-up" class='btn btn-outline-dark text-wrap rounded-5 fs-4 px-sm-4'>{el?.title}</button>
                            </Link>
                        )
                    })}
                </div>
            </div>
            {/* 
            <div class='col-11 m-auto'>
                {
                    products?.map((el, index) => {
                        return (
                            <div data-aos="fade-up" class='row gap-lg-0 gap-4 border border-light align-items-center rounded-5 flex-column-reverse flex-lg-row p-0 m-0 mt-5' id={`investD${index}`} style={{ overflow: 'hidden', backgroundImage: `url(${require('../../Images/invest2CrtBg.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', position: 'relative' }}  onClick={()=>handelCheckService(el?.id)}>
                                <div class='col-xl-7 col-lg-6' style={{ zIndex: '1' }}>
                                    <div class='text-start col-11 m-auto my-3 text-white'>
                                        <p class='fs-1 fweb'>{el?.title}</p>
                                        <p class='fs-4 fwt my-2'>{el?.subtitle}</p>
                                        <div dangerouslySetInnerHTML={{ __html: el?.description }}></div>
                                        <div class='investNow2 my-4' onClick={() => window.open(el?.link, '_blank')}  >
                                            <img src={require('../../Images/oINvestnow.png')} style={{ transform: `rotate(${scrollPos % 360}deg)`, }} alt="" />
                                            <div class='arrowBox'>
                                                <p class='fs-1 fwb'>
                                                    <FaLongArrowAltRight />
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class='col-xl-5 col-lg-6  p-0 d-flex justify-content-center align-items-center p-2' style={{ overflow: 'hidden', zIndex: '1' }}>
                                    <div class=' col-12' style={{ minHeight: '100px' }}>
                                        <img style={{ width: '100%' }} src={`${API}` + `${el?.image}`} class='invest2cartImg' alt="" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div> */}


            <div class='col-11 m-auto'>
                {
                    products?.map((el, index) => {
                        return (
                            <div data-aos="fade-up" class='row gap-lg-0 gap-4 border border-light align-items-center rounded-5 flex-column-reverse flex-lg-row p-0 m-0 mt-5' id={`investD${index}`} style={{ overflow: 'hidden', backgroundImage: `url(${require('../../Images/invest2CrtBg.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', position: 'relative' }} onClick={() => handelCheckService(el?.id)}>
                                <div class='col-11 m-auto'>
                                    <div class='d-flex align-items-center justify-content-between mt-3'>
                                        <div class='col-xl-3 col-md-4 ' style={{ height: 'auto' }}>
                                            <img style={{ height: '100%', width: '100%' }} src={`${API}` + `${el?.image}`} class='invest2cartImg' alt="" />
                                        </div>
                                        <div class='investNow2 investNow2pc my-4' onClick={() => window.open(el?.link, '_blank')}  >
                                            <img src={require('../../Images/oINvestnow.png')} style={{ width: '100%', transform: `rotate(${scrollPos % 360}deg)`, }} alt="" />
                                            <div class='arrowBox'>
                                                <p class='fs-1 fwb'>
                                                    <FaLongArrowAltRight />
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                    <div class='text-start mt-3 text-white'>
                                        <p class='fs-1 fweb'>{el?.title}</p>
                                        <p class='fs-4 fwt my-2'>{el?.subtitle}</p>
                                        <div dangerouslySetInnerHTML={{ __html: el?.description }}></div>
                                        <div class='investNow2 investNow2Mobile my-4' onClick={() => window.open(el?.link, '_blank')}  >
                                            <img src={require('../../Images/oINvestnow.png')} style={{ width: '100%', transform: `rotate(${scrollPos % 360}deg)`, }} alt="" />
                                            <div class='arrowBox'>
                                                <p class='fs-1 fwb'>
                                                    <FaLongArrowAltRight />
                                                </p>
                                            </div>

                                        </div>
                                        <br />
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }
            </div>

            <br />
            <br />

        </div>
    )
}

export default Invest2